import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMagnifyingGlass = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.41667 2.625C4.32259 2.625 2.625 4.32259 2.625 6.41667C2.625 8.51075 4.32259 10.2083 6.41667 10.2083C7.46383 10.2083 8.41114 9.78442 9.09778 9.09778C9.78442 8.41114 10.2083 7.46383 10.2083 6.41667C10.2083 4.32259 8.51075 2.625 6.41667 2.625ZM1.75 6.41667C1.75 3.83934 3.83934 1.75 6.41667 1.75C8.994 1.75 11.0833 3.83934 11.0833 6.41667C11.0833 7.54762 10.6806 8.58514 10.0114 9.39268L12.2854 11.6667L11.6667 12.2854L9.39268 10.0114C8.58514 10.6806 7.54762 11.0833 6.41667 11.0833C3.83934 11.0833 1.75 8.994 1.75 6.41667Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMagnifyingGlass);
export default ForwardRef;
