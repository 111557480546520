import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWindowSparkle = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4H22V9H20.5V5.5H3.5V18.5H11V20H2V4ZM13 16.75V15.25C14.6446 15.25 15.6575 14.886 16.2718 14.2718C16.886 13.6575 17.25 12.6446 17.25 11H18.75C18.75 12.6446 19.114 13.6575 19.7282 14.2718C20.3425 14.886 21.3554 15.25 23 15.25V16.75C21.3554 16.75 20.3425 17.114 19.7282 17.7282C19.114 18.3425 18.75 19.3554 18.75 21H17.25C17.25 19.3554 16.886 18.3425 16.2718 17.7282C15.6575 17.114 14.6446 16.75 13 16.75ZM18 17.5459C18.184 17.2234 18.4053 16.9299 18.6676 16.6676C18.9299 16.4052 19.2234 16.184 19.5459 16C19.2234 15.816 18.9299 15.5948 18.6676 15.3324C18.4053 15.0701 18.184 14.7766 18 14.4541C17.816 14.7766 17.5948 15.0701 17.3324 15.3324C17.0701 15.5948 16.7766 15.816 16.4541 16C16.7766 16.184 17.0701 16.4052 17.3324 16.6676C17.5948 16.9299 17.816 17.2234 18 17.5459Z"
      fill="currentColor"
    />
    <path
      d="M7 8C7 8.55228 6.55228 9 6 9C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7C6.55228 7 7 7.44772 7 8Z"
      fill="currentColor"
    />
    <path
      d="M10 8C10 8.55228 9.55228 9 9 9C8.44772 9 8 8.55228 8 8C8 7.44772 8.44772 7 9 7C9.55228 7 10 7.44772 10 8Z"
      fill="currentColor"
    />
    <path
      d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWindowSparkle);
export default ForwardRef;
