import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgModeIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.5 0.125C4.15076 0.125 0.625 3.65076 0.625 8C0.625001 12.3492 4.15076 15.875 8.5 15.875C12.8492 15.875 16.375 12.3492 16.375 8C16.375 3.65076 12.8492 0.125 8.5 0.125ZM13.1068 12.0755H11.3104V8.01265L12.0297 5.69568L11.52 5.51495L9.18858 12.0755H7.82226L5.49082 5.51495L4.98116 5.69568L5.70047 8.01265V12.0755H3.904V3.92451H6.57883L8.23794 8.59099V9.96093H8.78013V8.59099L10.4392 3.92451H13.1141V12.0755H13.1068Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgModeIcon);
export default ForwardRef;
