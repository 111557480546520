import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRoundedCheck = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.9974 1.91602C5.08538 1.91602 1.91406 5.08733 1.91406 8.99935C1.91406 12.9114 5.08538 16.0827 8.9974 16.0827C12.9094 16.0827 16.0807 12.9114 16.0807 8.99935C16.0807 5.08733 12.9094 1.91602 8.9974 1.91602ZM0.664062 8.99935C0.664062 4.39698 4.39502 0.666016 8.9974 0.666016C13.5998 0.666016 17.3307 4.39698 17.3307 8.99935C17.3307 13.6017 13.5998 17.3327 8.9974 17.3327C4.39502 17.3327 0.664062 13.6017 0.664062 8.99935ZM12.3769 6.82807L7.79379 12.4296L5.19685 9.83268L6.08073 8.9488L7.701 10.5691L11.4094 6.03652L12.3769 6.82807Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRoundedCheck);
export default ForwardRef;
