import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLeftRightArrows = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.83335 0.459534L13.2071 3.83331L9.83335 7.20708L9.12624 6.49997L11.2929 4.33331H1.00001V3.33331H11.2929L9.12624 1.16664L9.83335 0.459534ZM4.87379 7.49997L2.70712 9.66664H13V10.6666H2.70712L4.87379 12.8333L4.16668 13.5404L0.792908 10.1666L4.16668 6.79287L4.87379 7.49997Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLeftRightArrows);
export default ForwardRef;
