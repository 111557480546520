import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgChainIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1109 2.39186C10.6998 0.980708 8.41182 0.980708 7.00067 2.39186L6.00279 3.38974L5.29568 2.68264L6.29356 1.68475C8.09524 -0.116924 11.0163 -0.116923 12.818 1.68475C14.6197 3.48643 14.6197 6.40753 12.818 8.20921L11.8187 9.20851L11.1116 8.5014L12.1109 7.5021C13.5221 6.09095 13.5221 3.80302 12.1109 2.39186ZM9.87638 5.3335L5.83594 9.37394L5.12883 8.66683L9.16927 4.62639L9.87638 5.3335ZM3.89561 5.49691L2.8943 6.49823C1.48315 7.90938 1.48315 10.1973 2.8943 11.6085C4.30546 13.0196 6.59339 13.0196 8.00454 11.6085L9.00112 10.6119L9.70823 11.319L8.71165 12.3156C6.90997 14.1172 3.98887 14.1172 2.1872 12.3156C0.385519 10.5139 0.385517 7.5928 2.1872 5.79112L3.18851 4.78981L3.89561 5.49691Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChainIcon);
export default ForwardRef;
