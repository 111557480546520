import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRobot = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 2V4H21.5V8H22.5V12H21.5V16.25C21.5 18.3211 19.8211 20 17.75 20H6.25C4.17893 20 2.5 18.3211 2.5 16.25V12H1.5V8H2.5V4H11.25V2H12.75ZM4 5.5V16.25C4 17.4926 5.00736 18.5 6.25 18.5H17.75C18.9926 18.5 20 17.4926 20 16.25V5.5H4ZM7 10C7 9.17157 7.67157 8.5 8.5 8.5C9.32843 8.5 10 9.17157 10 10C10 10.8284 9.32843 11.5 8.5 11.5C7.67157 11.5 7 10.8284 7 10ZM14 10C14 9.17157 14.6716 8.5 15.5 8.5C16.3284 8.5 17 9.17157 17 10C17 10.8284 16.3284 11.5 15.5 11.5C14.6716 11.5 14 10.8284 14 10ZM9 14H15V15.5H9V14Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRobot);
export default ForwardRef;
