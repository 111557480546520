import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLibrary = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99996 2.5H11.6666V5.39462L15.7308 4.30563L18.966 16.3797L14.1364 17.6738L11.6666 8.45648V17.5H1.66663V4.16667H4.99996V2.5ZM6.24996 5.83333H10.4166V3.75H6.24996V5.83333ZM10.4166 7.08333H6.24996V12.9167H10.4166V7.08333ZM10.4166 14.1667H6.24996V16.25H10.4166V14.1667ZM4.99996 16.25V5.41667H2.91663V16.25H4.99996ZM12.4321 6.48361L15.0203 16.1429L17.4351 15.4958L14.8469 5.83656L12.4321 6.48361Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLibrary);
export default ForwardRef;
