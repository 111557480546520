import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCreditCard = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.16675 2.33496H12.8276L12.8289 2.77119C12.8313 3.60798 12.8312 4.4448 12.8312 5.28085C12.8311 5.4164 12.8311 5.55194 12.8311 5.68745V11.6643H1.16675V2.33496ZM2.04175 3.20996V5.24995H11.9562C11.9562 4.56959 11.9562 3.88974 11.9549 3.20996H2.04175ZM11.9561 6.12495H2.04175V10.7893H11.9561V6.12495Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCreditCard);
export default ForwardRef;
