import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgShieldKeyhole = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1.14258L12.25 2.89258V6.94844C12.25 8.54525 11.5596 9.72378 10.5824 10.651C9.62161 11.5627 8.36526 12.2481 7.20772 12.8726L7 12.9846L6.79228 12.8726C5.63474 12.2481 4.37838 11.5627 3.41761 10.651C2.44038 9.72378 1.75 8.54525 1.75 6.94844V2.89258L7 1.14258ZM2.625 3.52324V6.94844C2.625 8.25224 3.1742 9.21386 4.01989 10.0163C4.83118 10.7861 5.89728 11.3928 7 11.9902C8.10272 11.3928 9.16882 10.7861 9.98011 10.0163C10.8258 9.21386 11.375 8.25224 11.375 6.94844V3.52324L7 2.06491L2.625 3.52324ZM7.4375 7.21656C7.94727 7.03638 8.3125 6.55021 8.3125 5.97874C8.3125 5.25387 7.72487 4.66624 7 4.66624C6.27513 4.66624 5.6875 5.25387 5.6875 5.97874C5.6875 6.55021 6.05273 7.03638 6.5625 7.21656V9.04124H7.4375V7.21656Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgShieldKeyhole);
export default ForwardRef;
