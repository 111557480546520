import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCodeInsert = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1628 1.66699V3.33366H18.3295V16.667H14.1628V18.3337H12.9128V1.66699H14.1628ZM14.1628 15.417H17.0794V4.58366H14.1628V15.417ZM1.66663 3.33366H11.6666V4.58366H2.91663V15.417H11.6666V16.667H1.66663V3.33366ZM7.08329 7.03311L10.0505 10.0003L7.08329 12.9675L6.19941 12.0837L8.28274 10.0003L6.19941 7.91699L7.08329 7.03311Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCodeInsert);
export default ForwardRef;
